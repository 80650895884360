<template>
  <v-list dense>
    <v-list-item v-for="header in filteredHeaders" :key="header.name">
      <slot :name="header.value" :header="header.name" :value="data[header.value]" :data="data">
        <v-list-item-content>{{ header.name }}</v-list-item-content>
        <v-list-item-content v-if="!(data[header.value] === true || data[header.value] === false)" class="justify-end">
            {{ data[header.value] }}
        </v-list-item-content>
        <v-list-item-content v-else-if="data[header.value]" class="justify-end">
          Да
        </v-list-item-content>
        <v-list-item-content v-else class="justify-end">
          Нет
        </v-list-item-content>
      </slot>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "PropertiesList",
  computed: {
    filteredHeaders() {
      return this.headers.filter((e) => {
        if (e.value in this.data) {
          if (this.showEmpty) {
            return true
          }
          return !(this.data[e.value] === null
              || this.data[e.value] === undefined
              || this.data[e.value] === '')
        }
      })
    }
  },
  props: {
    showEmpty: {},
    headers: [],
    data: {},
  }
}
</script>

<style scoped>

</style>