<template>
  <div>
    <h1>Справочник</h1>
    <v-row  v-if="referenceData && referenceData.id">
      <v-col cols="3"></v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>{{referenceData.name}}</v-card-title>
          <v-divider />
          <properties-list :headers="headers" :data="referenceData" />
          <v-divider />
          <v-data-table
              :headers="valuesHeaders"
              :items="referenceData.values"
              item-key="key"
              disable-pagination
              hide-default-footer
              :sort-by="['key']"
              class="elevation-1"
              dense>
            <template v-slot:item.isActive="{ item }">
              <span v-if="item.isActive">Да</span>
              <span v-else>Нет</span>
            </template>
            <template v-slot:item.remark="{ item }">
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs"  v-if="item.remark.length > 20">{{ item.remark.substring(0, 20) }}...</span>
                  <span v-on="on" v-bind="attrs"  v-else>{{ item.remark}}</span>
                </template>
                <span >{{ item.remark }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
    <h3 v-else>Не найдено</h3>
  </div>
</template>

<script>
import PropertiesList from "../components/PropertiesList";

export default {
  name: "Reference",
  data: function () {
    return {
      headers: [
          {value: 'id', name: 'ID:'},
          {value: 'cft', name: 'CFT:'},
          {value: 'type', name: 'Тип:'},
      ],
      valuesHeaders: [
        {text: 'Ключ', value: 'key'},
        {text: 'Значение', value: 'value'},
        {text: 'Примечание', value: 'remark'},
        {text: 'Активен', value: 'isActive'},
      ]
    }
  },
  watch: {
    $route(to) {
      this.$store.dispatch('fetchReference', to.params.id);
    },
  },
  computed: {
    referenceData() {
      return this.$store.getters.referenceData
    }
  },
  mounted() {
    this.$store.dispatch('fetchReference', this.$route.params.id);
  },
  components: {
    'PropertiesList': PropertiesList
  }
}
</script>

<style scoped>

</style>